.sidebar-container{
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

}

.profile-img{
  width: 50px;
  height: 50px;
  border-radius: 20px;
  margin-top: 20px;
}