a {
  text-decoration: none !important;
}

.btn-body{
  height: 80;
  width: 80px;
  border-radius: 20px;
  color: #F8D9C0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5px auto;
  transition: all .2s ease;
  background-color: rgba(244, 187, 146, 0.0);
}

.btn-body.active{
  background-color: rgba(244, 187, 146, 1);
  color:white;
  transform: scale(1.05);
}

.btn-body:hover{
  color: white;
}

.btn-title{
  margin: 4px auto;
  font-weight: 600;
  font-size: 14px;
  
}