circle{
  transition: all 1s ease-in-out;
  transform: rotate(93deg);
  transform-origin: 50% 50%;

}

.progress-circle{
  justify-content: center;
  align-items: center;
}

image.active{
  animation: rotation 30s infinite linear;
  transform-origin: 50% 50%;
}

@keyframes rotation{
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(359deg);
  }
}