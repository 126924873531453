.left-player-body {
  width: 68%;
  margin-right: 2%;
  height: 100%;
}

.right-player-body {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}