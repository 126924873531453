.entry-body {
  width: 100%;
  height: 19%;
  align-items: center;
  margin-top: 10%;
}

.entry-image {
  height: 50px;
  width: 50px;
  border-radius: 15px;
  margin-right: 10px;
}

.entry-right-body {
  flex-direction: column;
  justify-content: center;
}

.entry-title {
  font-weight: 700;
  font-size: 16px;
  color: #c9d0e3;
  margin: 0px 0px 5px;
}

.entry-subtitle {
  font-weight: 400;
  font-size: 12px;
  color: #c4d0e37c;
  margin: 0;
}