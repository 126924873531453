.controls-wrapper {
  width: 50%;
  margin: 0;
  align-items: center;
  justify-content: space-evenly;
}

.action-btn {
  width: 50px;
  height: 50px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.action-btn:hover {
  transform: scale(1.1);
}

.play-pause-btn {
  width: 70px;
  height: 70px;
  border-radius: 30px;
  background-color: #c96850;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: inset -4px -4px 10px #55291e, inset 4px 4px 10px 1px #ffae9a;
}

.play-pause-btn.active{
  box-shadow: inset -4px -4px 10px #ff8769, inset 4px 4px 10px 1px #753d30;
}

.play-pause-btn:hover {
  transform: scale(1.1);
}
