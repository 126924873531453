.screen-container {
  width: calc(100% - 100px);
  height: 100%;
  background-color: #1e2a3e;
  border: 30px;
  background-color: #1e2a3e;
  opacity: 1;
  background-image: repeating-radial-gradient(
      circle at center left,
      transparent 0,
      #1e2a3e 100px
    ),
    repeating-linear-gradient(#23365655, #233656);
}

.flex{
  display: flex;
  
}