.albumInfo-card{
  margin-top: 20px;
  width: 80%;
}

.albumInfo-card p{
  margin: 5px auto !important;
}

.albumName-container{
  font-size: 20px;
  font-weight: 700;
  color: #c3d0e3;
  width: 100%;
  overflow: hidden;
}

.marquee{
  white-space: nowrap;
  display: inline-block;
  animation: marquee 12s linear infinite;
  padding-left: 100%;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.album-info{
  font-size: 14px;
  font-weight: 500;
  color: #9aa9c2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.album-release{
  font-size: 12px;
  font-weight: 400;
  color: #9aa9c2;
  margin-top: 10px;
}

